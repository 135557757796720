import {Close} from "@bphxd/ds-core-react";
import {NUMBER_OF_DECIMALS} from "constants/common";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {
  getFormattedNumberWithCheck,
  preventZeroOrNegative,
} from "utils/numberUtil";

const MandateAllocateModal = ({
  showModal,
  setShowModal,
  retiredQty,
  accuredQty,
  unitOfMeasure,
  onSubmit,
  loading,
  isOneCustomer,
}) => {
  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    getValues,
    watch,
    trigger,
  } = methods;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const watchQuantity = watch("sustainableQtyM3");

  const checkQuantity = useMemo(() => {
    const sustainQty = parseFloat(retiredQty);
    const fQty = parseFloat(accuredQty);
    const formQuantity = parseFloat(watchQuantity);
    return sustainQty < fQty ? formQuantity > sustainQty : formQuantity > fQty;
  }, [retiredQty, accuredQty, watchQuantity]);

  const headerText = isOneCustomer ? "Allocate" : "Confirm allocation";

  const showSustainableQty = getFormattedNumberWithCheck(
    retiredQty,
    NUMBER_OF_DECIMALS,
  );

  const showFuelQty = getFormattedNumberWithCheck(
    accuredQty,
    NUMBER_OF_DECIMALS,
  );

  return (
    <Modal
      size="sm"
      isOpen={showModal}
      className="modal-dialog-centered [&>div]:w-[330px]"
    >
      {isOneCustomer && (
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 mb-2 text-xl !px-[20px]"
          close={<Close onClick={() => setShowModal(false)} />}
        >
          {headerText}
        </ModalHeader>
      )}
      {!isOneCustomer && (
        <ModalBody className="text-center !px-[20px]">
          <p className="mb-[2px]">{headerText}</p>
          <p className="text-[13px] text-gray-800 mb-0">
            Are you sure you want to allocate the selected retired quantity to
            the customer mandates?
          </p>
        </ModalBody>
      )}
      {isOneCustomer && (
        <ModalBody className="flex flex-col items-center w-[330px] !pt-[17px] !pb-[52px] !px-[20px]">
          <div className="w-full">
            <FormProvider {...methods}>
              <Form>
                <Label for="sustainableQtyM3">Sustainable quantity m³</Label>
                <Input
                  id="sustainableQtyM3"
                  type="number"
                  placeholder="Enter quantity"
                  {...computeProps("sustainableQtyM3", {
                    required: "Sustainable quantity is required",
                    pattern: preventZeroOrNegative,
                  })}
                  invalid={!!errors.sustainableQtyM3 || checkQuantity}
                />
                {errors.sustainableQtyM3 && (
                  <FormFeedback>{errors.sustainableQtyM3.message}</FormFeedback>
                )}
                {!errors.sustainableQtyM3 && checkQuantity && (
                  <FormFeedback className="!block">
                    It cannot exceed either of the quantities below
                  </FormFeedback>
                )}
                <FormFeedback className="!block !text-gray-700 !tracking-[0.2px]">
                  You have max {showSustainableQty}
                  {unitOfMeasure} sustainable available. ({showFuelQty}
                  {unitOfMeasure} accured quantity for this customer)
                </FormFeedback>

                <Label className="mt-[30px]" for="notes">
                  Notes
                </Label>
                <Input
                  id="notes"
                  type="textarea"
                  rows="3"
                  {...computeProps("notes")}
                />
                <FormFeedback className="!block !text-gray-700 !tracking-[0.2px]">
                  Please ensure that an equivalent quantity of fuel volume has
                  been sold to customers.
                </FormFeedback>
              </Form>
            </FormProvider>
          </div>
        </ModalBody>
      )}

      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 bg-transparent text-default"
              onClick={() => {
                trigger().then((isValid) => {
                  if (isValid) {
                    onSubmit(getValues());
                  }
                });
              }}
              disabled={errors.sustainableQtyM3 || checkQuantity || loading}
            >
              {loading && <Spinner size="sm" className="btn-icon-prefix" />}
              Allocate
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

MandateAllocateModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  retiredQty: PropTypes.number,
  accuredQty: PropTypes.number,
  unitOfMeasure: PropTypes.string,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  isOneCustomer: PropTypes.bool,
};

export default MandateAllocateModal;

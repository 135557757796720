import {
  COUNTRY_AUSTRIA,
  COUNTRY_CYPRUS,
  COUNTRY_FINLAND,
  COUNTRY_GERMANY,
  COUNTRY_GREECE,
  COUNTRY_NETHERLANDS,
  COUNTRY_NORWAY,
  COUNTRY_POLAND,
  COUNTRY_PORTUGAL,
  COUNTRY_ROMANIA,
  COUNTRY_SPAIN,
  COUNTRY_SWEDEN,
  COUNTRY_UK,
} from "constants/countryDetails";
import {
  DIV_CODE_COPRO,
  DIV_CODE_GF,
  DIV_CODE_SAF,
} from "constants/divisionDetails";
import {MODULE_NAMES} from "constants/moduleDetails";
import {useAppSetting} from "providers/appSetting";

export const getSiteDetails = (countryId) => {
  const {appSetting} = useAppSetting();
  const siteData = appSetting?.siteData;
  const country_id = String(countryId);
  const siteReferenceData = siteData.find(
    (site) => site.countryId === country_id,
  );
  return siteReferenceData;
};

export const getDivisionDetailsById = (divisionId) => {
  const {appSetting} = useAppSetting();
  const divisionData = appSetting?.divisionData;
  const filtereddivision = divisionData.find(
    (data) => data.divisionId === divisionId,
  );
  return filtereddivision;
};

export const getDivisionData = (divisionCode) => {
  const {appSetting} = useAppSetting();
  const divisionData = appSetting?.divisionData;
  const filtereddivision = divisionData.find(
    (data) => data.divisionCode === divisionCode,
  );

  return filtereddivision;
};

export const setCountryDetails = async (countryName) => {
  const {appSetting, setAppSetting} = useAppSetting();

  if (
    appSetting?.currentCountry?.toLowerCase() === "global" &&
    appSetting?.featureMappingData &&
    countryName?.length > 0
  ) {
    const countryFeatureData = appSetting?.featureMappingData?.find(
      (item) => item.countryName.toLowerCase() === countryName.toLowerCase(),
    );
    setAppSetting({
      ...appSetting,
      currentCountry: countryName?.toUpperCase(),
      currentCountryMappingData: countryFeatureData,
      currentCountryCode: countryFeatureData?.countryCode,
    });
  }
};

export const setCountryModuleDetails = async (countryName, moduleName) => {
  const {appSetting, setAppSetting} = useAppSetting();

  if (
    (appSetting?.currentModule === undefined ||
      appSetting?.currentModule === "") &&
    appSetting?.featureMappingData &&
    moduleName?.length > 0
  ) {
    setAppSetting({
      ...appSetting,
      currentModule: moduleName,
      currentModuleTitle: MODULE_NAMES[moduleName],
    });
  }
  if (
    appSetting?.currentCountry?.toLowerCase() === "global" &&
    appSetting?.featureMappingData &&
    countryName?.length > 0
  ) {
    const countryFeatureData = appSetting?.featureMappingData?.find(
      (item) => item.countryName.toLowerCase() === countryName.toLowerCase(),
    );
    setAppSetting({
      ...appSetting,
      currentCountry: countryName?.toUpperCase(),
      currentCountryMappingData: countryFeatureData,
      currentCountryCode: countryFeatureData?.countryCode,
    });
  }
};

export const setGlobalCountry = async () => {
  const {appSetting, setAppSetting} = useAppSetting();

  if (appSetting?.currentCountry?.toLowerCase() !== "global") {
    const countryFeatureData = appSetting?.featureMappingData?.find(
      (item) => item.countryName.toLowerCase() === "global",
    );
    setAppSetting({
      ...appSetting,
      currentSite: "GLOBAL",
      currentCountry: "GLOBAL",
      currentCountryMappingData: countryFeatureData,
    });
  }
};

export const setModuleDetails = async (moduleName, moduleTitle) => {
  const {appSetting, setAppSetting} = useAppSetting();

  if (
    appSetting?.currentModule?.toLowerCase() !== moduleName?.toLowerCase() &&
    moduleName?.length > 0
  ) {
    setAppSetting({
      ...appSetting,
      currentModule: moduleName,
      currentModuleTitle: moduleTitle,
    });
  }
};

export const getDivisionDataByCountry = (selectedCountry) => {
  switch (selectedCountry) {
    case COUNTRY_SPAIN:
      return getDivisionData(DIV_CODE_COPRO);
    case COUNTRY_POLAND:
      return getDivisionData(DIV_CODE_GF);
    case COUNTRY_UK:
      return getDivisionData(DIV_CODE_GF);
    case COUNTRY_SWEDEN:
      return getDivisionData(DIV_CODE_SAF);
    case COUNTRY_NORWAY:
      return getDivisionData(DIV_CODE_SAF);
    case COUNTRY_GERMANY:
      return getDivisionData(DIV_CODE_SAF);
    case COUNTRY_PORTUGAL:
      return getDivisionData(DIV_CODE_SAF);
    case COUNTRY_AUSTRIA:
      return getDivisionData(DIV_CODE_SAF);
    case COUNTRY_FINLAND:
      return getDivisionData(DIV_CODE_SAF);
    case COUNTRY_NETHERLANDS:
      return getDivisionData(DIV_CODE_SAF);
    case COUNTRY_ROMANIA:
      return getDivisionData(DIV_CODE_SAF);
    case COUNTRY_CYPRUS:
      return getDivisionData(DIV_CODE_SAF);
    case COUNTRY_GREECE:
      return getDivisionData(DIV_CODE_SAF);
    default:
      return getDivisionData(DIV_CODE_GF);
  }
};

export const getDivisionNameByCode = (divisionCode) => {
  switch (divisionCode) {
    case DIV_CODE_COPRO:
      return "Co-processing";
    case DIV_CODE_GF:
      return "Ground Fuels";
    case DIV_CODE_SAF:
      return "SAF";
    default:
      return "GF";
  }
};

export default {getDivisionData, getSiteDetails};

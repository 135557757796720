import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useCallback, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import {NUMBER_OF_DECIMALS} from "constants/common";
import {COUNTRY_GERMANY, COUNTRY_UK} from "constants/countryDetails";
import {DIV_CODE_GF, DIV_CODE_SAF} from "constants/divisionDetails";
import {areValuesEqualIgnoreCase} from "utils/helpers/checkData";
import {getFormattedNumberWithCheck} from "utils/numberUtil";
import {actionConfig, linkAction} from "../utils";
import ModalToShow from "./ModalToShow";

import {actionItems, massBalanceType, status} from "../constants";

const ActionDropdown = ({
  row,
  table,
  navigateToAllocation,
  currentFilterValues,
  periodStatus,
}) => {
  const [actionModalVisible, setActionModalVisible] = useState("");
  const {division, country} = useParams();
  const divisionCode = division.toUpperCase();
  const linkError = row?.original.outboundRecords?.linkError;
  const rowStatus = row?.original.outboundRecords?.status;
  const fromMbLocationGroupId =
    row?.original.inboundRecord?.fromMbLocationGroupId ?? null;
  const recordStatus = row?.original.inboundRecord?.recordStatus;

  let finalRowStatus = rowStatus;

  if (
    linkError &&
    (linkError === "PURCHASE" || linkError === "PURCHASE_SALES") &&
    rowStatus === status.AVAILABLE
  ) {
    finalRowStatus = status.INVALID_PURCHASE;
  } else if (
    linkError &&
    ["PURCHASE_SALES", "SALES"].includes(linkError) &&
    [status.ALLOCATED, status.ALLOCATED_AND_SENT].includes(rowStatus) &&
    divisionCode === DIV_CODE_SAF
  ) {
    finalRowStatus = status.INVALID_OUTGOING;
  } else if (
    finalRowStatus === status.AVAILABLE &&
    fromMbLocationGroupId !== null
  ) {
    finalRowStatus = status.AVAILABLE_FROM_TRANSFER;
  }
  const isTransfer = useMemo(() => {
    return rowStatus === status.AVAILABLE && fromMbLocationGroupId !== null;
  }, [fromMbLocationGroupId, rowStatus]);

  const isDocumentGenerated = useMemo(() => {
    return (
      row?.original?.outboundRecords?.document !== null &&
      row?.original?.outboundRecords?.document !== "IN PROGRESS" &&
      divisionCode !== DIV_CODE_GF
    );
  }, [divisionCode, row?.original?.outboundRecords?.document]);

  const isMbClosed = useMemo(() => {
    return periodStatus === "CLOSED" || recordStatus === "CLOSED";
  }, [periodStatus, recordStatus]);

  const isReceipientPPOSExists = useMemo(() => {
    return row?.original?.outboundRecords?.isReceipientPPOSExist !== null;
  }, [row]);

  const isActualizationId = useMemo(() => {
    return row?.original?.inboundRecord?.actualizationId !== null;
  }, [row]);

  const isOutQtyLess = useMemo(() => {
    const value = getFormattedNumberWithCheck(
      row?.original?.outboundRecords?.quantityOutM3,
      NUMBER_OF_DECIMALS,
      true,
    );
    return value === "< 0.00001";
  }, [row?.original?.outboundRecords]);

  const isDocWithNabisy = useMemo(() => {
    return isDocumentGenerated && isReceipientPPOSExists;
  }, [isDocumentGenerated, isReceipientPPOSExists]);

  if (isTransfer) {
    finalRowStatus = status.AVAILABLE_FROM_TRANSFER;
  }

  if (
    isMbClosed ||
    (finalRowStatus === status.AVAILABLE && isDocWithNabisy) // This is for Carry over status
  ) {
    finalRowStatus = status.CARRIED_OVER;
  }

  if (
    isDocumentGenerated &&
    finalRowStatus !== status.INVALID_OUTGOING &&
    finalRowStatus !== status.CARRIED_OVER
  ) {
    finalRowStatus = status.ALLOCATED_AND_SENT;
  }

  const navigate = useNavigate();

  const menuItemToBeDisabled =
    linkError &&
    finalRowStatus !== status.INVALID_OUTGOING &&
    finalRowStatus !== status.INVALID_PURCHASE;

  const availableActions = useMemo(
    () => [
      actionItems.VIEW_DETAILS,
      actionItems.REMOVE_ALLOCATION,
      actionItems.REMOVE_DOCUMENT,
      actionItems.REMOVE_FROM_BALANCE,
      actionItems.REMOVE_RESERVATION,
      actionItems.REMOVE_WRITE_OFF,
      actionItems.REMOVE_TRANSFER,
      actionItems.UNRETIRE_FOR_MANDATE,
    ],
    [],
  );

  const menuItemToBeDisabledForCoproTepsa = useCallback(
    (action) => {
      return (
        (row?.original?.outboundRecords?.status === status.AVAILABLE ||
          row?.original?.outboundRecords?.status === status.AVAILABLE_CO ||
          row?.original?.outboundRecords?.status ===
            status.AVAILABLE_FROM_TRANSFER) &&
        action === actionItems.RETIRE_FOR_MANDATE_SNS &&
        currentFilterValues?.mbLocationGroupName === "Tepsa Tarragona"
      );
    },
    [row, currentFilterValues?.mbLocationGroupName],
  );

  const menuItemToBeDisabledForGeneratedDocuments = useCallback(
    (action) => {
      return (
        (isDocumentGenerated ||
          row?.original?.outboundRecords?.document === "IN PROGRESS") &&
        action === actionItems.GENERATE_DOCUMENT
      );
    },
    [isDocumentGenerated, row?.original?.outboundRecords?.document],
  );

  const menuItemToBeDisabledNabisyGenerateDocument = useCallback(
    (action) => {
      return (
        areValuesEqualIgnoreCase(action, actionItems.GENERATE_DOCUMENT) &&
        isReceipientPPOSExists &&
        areValuesEqualIgnoreCase(
          currentFilterValues?.mbBalanceTypeCode,
          massBalanceType.TR,
        ) &&
        row?.original?.outboundAllRecords?.some((record) =>
          [
            status.AVAILABLE,
            status.AVAILABLE_CO,
            status.AVAILABLE_FROM_IMPORT,
          ].includes(record.status),
        )
      );
    },
    [
      currentFilterValues?.mbBalanceTypeCode,
      isReceipientPPOSExists,
      row?.original?.outboundAllRecords,
    ],
  );

  const menuItemToBeHideForGeneratedDocuments = useCallback(
    (action) => {
      return (
        !isReceipientPPOSExists &&
        finalRowStatus === status.RETIRED &&
        action === actionItems.GENERATE_DOCUMENT
      );
    },
    [finalRowStatus, isReceipientPPOSExists],
  );

  const menuItemToBeHideForAvailableTransfer = useCallback(
    (action) => {
      return (
        !row?.original?.inboundRecord?.incomingDocument &&
        (areValuesEqualIgnoreCase(
          finalRowStatus,
          status.AVAILABLE_FROM_TRANSFER,
        ) ||
          areValuesEqualIgnoreCase(
            finalRowStatus,
            status.AVAILABLE_FROM_IMPORT,
          )) &&
        (areValuesEqualIgnoreCase(action, actionItems.ALLOCATE) ||
          areValuesEqualIgnoreCase(action, actionItems.WRITE_OFF))
      );
    },
    [finalRowStatus, row?.original?.inboundRecord?.incomingDocument],
  );

  const menuItemToBeHideRemoveAllocation = useCallback(
    (action) => {
      return (
        areValuesEqualIgnoreCase(divisionCode, DIV_CODE_SAF) &&
        areValuesEqualIgnoreCase(country, COUNTRY_GERMANY) &&
        areValuesEqualIgnoreCase(action, actionItems.REMOVE_ALLOCATION) &&
        isDocumentGenerated &&
        isReceipientPPOSExists
      );
    },
    [country, divisionCode, isDocumentGenerated, isReceipientPPOSExists],
  );

  const menuItemToBeHideRemoveFromBalanceForAvailableStatus = useCallback(
    (action) => {
      return (
        areValuesEqualIgnoreCase(finalRowStatus, status.AVAILABLE) &&
        areValuesEqualIgnoreCase(action, actionItems.REMOVE_FROM_BALANCE) &&
        (isActualizationId || isTransfer)
      );
    },
    [finalRowStatus, isActualizationId, isTransfer],
  );

  const menuItemToBeHideRemoveFromBalanceForAvailablefromCarriedOverStatus =
    useCallback(
      (action) => {
        return (
          areValuesEqualIgnoreCase(finalRowStatus, status.AVAILABLE_CO) &&
          areValuesEqualIgnoreCase(action, actionItems.REMOVE_FROM_BALANCE) &&
          country === COUNTRY_UK
        );
      },
      [finalRowStatus, country],
    );

  const menuItemToBeHideUnlinkDocumentForAvailableStatus = useCallback(
    (action) => {
      return (
        areValuesEqualIgnoreCase(finalRowStatus, status.AVAILABLE) &&
        areValuesEqualIgnoreCase(action, actionItems.UNLINK_DOCUMENT) &&
        !isActualizationId
      );
    },
    [finalRowStatus, isActualizationId],
  );

  const menuItemToBeHideRemoveWriteOffIfQtyIsLess = useCallback(
    (action) => {
      return (
        areValuesEqualIgnoreCase(divisionCode, DIV_CODE_SAF) &&
        areValuesEqualIgnoreCase(finalRowStatus, status.WRITE_OFF) &&
        areValuesEqualIgnoreCase(action, actionItems.REMOVE_WRITE_OFF) &&
        isOutQtyLess
      );
    },
    [divisionCode, finalRowStatus, isOutQtyLess],
  );

  const ACTIONS = useMemo(
    () =>
      Object.values(actionItems).map((action) => ({
        name: action,
        isVisible:
          actionConfig[finalRowStatus + divisionCode]?.visible.includes(
            action,
          ) &&
          !menuItemToBeHideForGeneratedDocuments(action) &&
          !menuItemToBeHideRemoveFromBalanceForAvailableStatus(action) &&
          !menuItemToBeHideRemoveFromBalanceForAvailablefromCarriedOverStatus(
            action,
          ) &&
          !menuItemToBeHideUnlinkDocumentForAvailableStatus(action),
        isDisabled:
          !actionConfig[finalRowStatus + divisionCode]?.enabled.includes(
            action,
          ) ||
          (menuItemToBeDisabled && !availableActions.includes(action)) ||
          menuItemToBeDisabledForCoproTepsa(action) ||
          menuItemToBeDisabledForGeneratedDocuments(action) ||
          menuItemToBeDisabledNabisyGenerateDocument(action) ||
          menuItemToBeHideForGeneratedDocuments(action) ||
          menuItemToBeHideForAvailableTransfer(action) ||
          menuItemToBeHideRemoveAllocation(action) ||
          menuItemToBeHideRemoveFromBalanceForAvailableStatus(action) ||
          menuItemToBeHideRemoveFromBalanceForAvailablefromCarriedOverStatus(
            action,
          ) ||
          menuItemToBeHideUnlinkDocumentForAvailableStatus(action) ||
          menuItemToBeHideRemoveWriteOffIfQtyIsLess(action),
        action: () =>
          linkAction(
            divisionCode,
            setActionModalVisible,
            action,
            row,
            table,
            navigate,
            navigateToAllocation,
            country,
          ),
      })),
    [
      finalRowStatus,
      divisionCode,
      menuItemToBeHideForGeneratedDocuments,
      menuItemToBeDisabled,
      availableActions,
      menuItemToBeDisabledForCoproTepsa,
      menuItemToBeDisabledForGeneratedDocuments,
      menuItemToBeDisabledNabisyGenerateDocument,
      menuItemToBeHideForAvailableTransfer,
      menuItemToBeHideRemoveAllocation,
      menuItemToBeHideRemoveFromBalanceForAvailableStatus,
      menuItemToBeHideRemoveFromBalanceForAvailablefromCarriedOverStatus,
      menuItemToBeHideUnlinkDocumentForAvailableStatus,
      menuItemToBeHideRemoveWriteOffIfQtyIsLess,
      row,
      table,
      navigate,
      navigateToAllocation,
      country,
    ],
  );

  return (
    <>
      <UncontrolledDropdown direction="start">
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0"
          caret
          onClick={() => setActionModalVisible("")}
        >
          <Kebab24 />
        </DropdownToggle>
        <DropdownMenu container="body">
          {ACTIONS.filter((action) => action.isVisible).map((action) => (
            <DropdownItem
              key={action.name}
              onClick={action.action}
              disabled={action.isDisabled}
              data-test={`data-test-mb-table-action-${action.name}`}
            >
              {action.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {actionModalVisible && (
        <ModalToShow
          modal={actionModalVisible}
          setModal={setActionModalVisible}
          rowData={row}
          divisionCode={divisionCode}
          currentFilterValues={currentFilterValues}
        />
      )}
    </>
  );
};

ActionDropdown.propTypes = {
  row: PropTypes.object,
  table: PropTypes.object,
  navigateToAllocation: PropTypes.func,
  currentFilterValues: PropTypes.object,
  periodStatus: PropTypes.string,
};

export default ActionDropdown;
